import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Kantime = () => {
  const headingbanner = {
    title: `Kantime`,
    content: `A Hospice EMR Solutions Platform`,
  };

  const data = {
    images: ["kantime-project.webp", "kantime-project-2.webp"],
    firstAlt: "UI UX Mobile Screen To Monitor Daily Schedule And Performance",
    firstTitle: "Performance And Schedule Screen",
    secondAlt: "Mobile Features And Account Details Of Physician",
    secondTitle: "Accounts Details",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["HealthTech"],
      },
      {
        title: "What we did",
        text: ["UX Consultation", "UI UX Designing", "Design System Engineering"],
      },
      {
        title: "Platform",
        text: ["Web Application", "Mobile Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The team lacked a digital platform that can streamline their operations and facilitate easy communication in the home healthcare sector.`,
          `They used traditional and manual methods to schedule the visits of the nursing assistants which was an inefficient way from a long-term growth perspective.`,
          `It was often difficult to access the real-time availability of the nursing staff through emails which resulted in delayed services and bad business impressions.`,
          `Without a digital platform, it became difficult to allocate the right resources at the right place which might also result in lower patient engagement in the long-term.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `We created a mobile application to address their issue, facilitate seamless communication, streamline operations and increase the patient engagement rate. `,
          `They now have a single platform through which staff can look into their respective schedules, connect with the other members of the family, find the location of their visits etc. making it an effective way to offer home care services.`,
        ],
      },
    ],
    image: ["kantime-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Dashboard",
    para: [
      `We created a dashboard to show the overall performance of the nursing staff during different time intervals. It also reflects the pending messages and open visits to offer better services. `,
    ],
    content: {
      imageAlt: 'Mobile UI to show periodical performance, chat with the staff and download attachments ',
      imageTitle: 'Dashboard, Chats and Attachments',
      image: ["kantime-projectone.webp"],
      text: `Additionally, we also created an interface to facilitate two-way communication. The platform also allows files to share documents for better assistance.`,
    },
  };

  const projectData2 = {
    title: "Seamless Operations",
    para: [
      `We systematically represented the daily schedules of the staff for easy understanding of their tasks and also provided a map for seamless navigation which ultimately led to increased efficiency. `,
    ],
    content: {
      image: ["kantime-projecttwo.webp"],
      imageAlt: 'UI Design to show location on maps for schedule visits',
      imageTitle: 'Daily Visits',
    },
  };

  const projectData3 = {
    title: "My Account",
    para: [
      `Our all-in-one mobile application for home care solutions offers multiple services such as payroll from where the staff can check the balances, a calendar to set up and look into their schedule, chat to communicate with the patient and many more. `,
    ],
    content: {
      image: [
        "kantime-projectthree.webp",
        "kantime-projectthree-2.webp",
        "kantime-projectthree-3.webp",
        "kantime-projectthree-4.webp",
      ],
      firstAlt: 'More detail of the staff',
      firstTitle: 'Account Detail',
      secondAlt: 'Staff details based on priority',
      secondTitle: 'Staff Contacts',
      thirdAlt: 'List of Holidays, Clients details and notifications',
      thirdTitle: 'Holidays, Clients, Notifications',
      fourthAlt: 'Details of the visit time, location, payroll, etc. ',
      fourthTitle: 'Payroll and other information',
    },
  };

  const conclusionData = [
    {
      para: [
        `Being a home care solution provider, Kantime had a big issue to solve for long-term growth in the healthcare industry.`,
        `They earlier used a traditional method i.e. Emails to communicate the staff schedules which proved to be an inefficient way of communication ultimately leading to poor delivery of the services. `,
        `We helped them transform their traditional business digitally and offered an all-in-one platform to cater to all their needs. `,
        `With a user-friendly interface and a wide range of options, we made it easier for the nursing staff to communicate and cater to their patient's needs ultimately leading to improved business and long-term growth.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={12} />
      </div>
    </Layout>
  );
};

export default Kantime;

export const Head = () => (
  <Seo title="Kantime- Hospice EMR Solutions Case Study | Octet" description="We created a Web App and Mobile App platform to a platform that provides hospice EMR solutions and offered UX Consultation, UI UX Designing, etc." />
)